.root .infoPage {
  padding-bottom: 7em;
  padding-top: 7em;

  > main {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    figure {
      display: flex;
      flex-basis: 620px;
      flex-grow: 1;

      min-width: min(850px, 100%);

      div {
        background-size: cover;
        width: 100%;
        height: 100%;
        aspect-ratio: 16/9;
      }
    }

    aside {
      padding: 1em;
      background: rgba(13, 37, 62, 0.69);
      color: white;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      justify-content: center;
      align-items: center;
      min-width: 325px;
      gap: 1em;

      flex-basis: 0;
      flex-grow: 1;

      > * {
        display: flex;
        flex-direction: column;
        align-items: center;

        flex-basis: 205px;
        flex-grow: 1;
      }

      h4,
      .h4 {
        margin: 0.1em 0;
        margin-top: 1em;
        white-space: nowrap;

        &:first-child {
          margin-top: 0;
        }
      }

      ol {
        padding: 0;
        padding-left: 1em;
        margin: 0;
        margin-bottom: 1em;

        text-align: start;
      }

      // PRICE
      > :last-child {
        min-width: 185px;
        flex-basis: 0;

        .price {
          position: relative;
          font-size: 2em;

          .old {
            &::before {
              content: "";
              position: absolute;
              border: 1px solid;
              width: 100%;
              top: 50%;
            }

            position: absolute;
            margin-top: -0.8em;
            color: gray;
            font-size: 0.7em;
            margin-left: 0.6em;
          }

          small {
            color: inherit;
            font-weight: 200;
            opacity: 1;
            text-decoration: none;
          }

          img {
            position: absolute;
            width: 1.6em;
            rotate: -15deg;
            margin-left: -1.5em;
            margin-top: -0.6em;
            filter: drop-shadow(0px 0px 4px black);
          }
        }
      }
    }
  }
}
