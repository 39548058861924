.root .landingPage {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;

  position: relative;
  max-width: initial !important;
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.03125) 45%,
      rgba(0, 0, 0, 0.8155637254901961) 100%
    );

    z-index: 0;
  }

  * {
    z-index: 1;
  }

  strong {
    font-size: min(5em, 12vw);
    color: white;
    text-shadow: 0px 0px 5px black;
  }

  small {
    font-size: min(2em, 5vw);
    color: white;
    // text-shadow: 0 0 10px #0ea000;
    text-shadow: 0px 0px 5px black;
    font-weight: 200;
    opacity: 1;
  }

  button:last-child {
    display: inline-flex;
    background-color: initial;

    color: white !important;
    font-size: 2em !important;
    bottom: 1em;
    opacity: 0.7;

    padding: 0.1em;
    margin-bottom: 2vh;
    margin-top: 8vh;
  }

  .button {
    font-size: 1.6em;
    margin-top: 0.7rem;
  }
}
