.root .bookingPage {
  padding: 0 0.5rem;

  > header {
    padding-top: 2em;
  }

  form {
    position: relative;
    gap: 1.3em;

    .overlay {
      text-align: center;
      backdrop-filter: blur(5px);
      font-size: min(4em, 10vw);
      box-shadow: 0px 0px 8px black;
      font-family: "Kanit";
      font-weight: bold;
      text-shadow: 0px 0px 5px black;
    }

    em {
      position: absolute;
      font-size: 0.8rem;
      font-style: italic;
      opacity: 0.6;
    }

    .startTimeFields {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 1rem;
      gap: 1rem;
      row-gap: 0;

      font-size: max(1.9em, 2.3vw);

      ::placeholder {
        visibility: visible;
        opacity: 0.6;
        font-size: 0.98em;
      }

      > label,
      > .label {
        display: inline-flex;
        align-items: center;
        flex-direction: initial;
        color: white;
        flex-basis: initial;
        flex-grow: initial;
        gap: 0;
        // NOTE: Required for react-datepicker position
        position: relative;
        border-bottom: 2px solid #00ffd0;
        line-height: initial;

        svg {
          position: initial;
          font-size: 0.9em;
          margin: 0;
          margin-right: 0.2em;
          margin-top: 0.3em;
          cursor: pointer;
        }

        input {
          border-bottom: none;
          min-width: initial;
        }
      }

      button {
        // font-size: 2rem;
        // padding: 0.5em;
        margin: 0;
      }

      input.medium {
        width: 7em;
      }

      input.small {
        width: 3.5em;
      }

      input[type="text"] {
        margin-bottom: 0;
        color: #f0f0f0;
      }

      input[type="time"] {
        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }

      input[type="date"] {
        max-width: 7em;

        &::calendar-picker-indicator {
          display: none !important;
        }

        &::-webkit-calendar-picker-indicator {
          filter: contrast(0%) brightness(350%);
          cursor: pointer;
          max-width: 0.7em;
          background-position: center;
        }
      }

      input[type="date"],
      input[type="time"] {
        background: initial;
        border: initial;
        padding: 0;
        border-radius: 5px;
        line-height: 1;
        margin: 5px;
        text-align: center;

        /* 
          The naming convention for the hour, minute, second, and am/pm field is
          `-webkit-datetime-edit-{field}-field` 
          */
        // /* Hour */
        // ::-webkit-datetime-edit-hour-field {
        //   background-color: #f2f4f5;
        //   border-radius: 15%;
        //   padding: 19px 13px;
        // }

        // /* Minute */
        // ::-webkit-datetime-edit-minute-field {
        //   background-color: #f2f4f5;
        //   border-radius: 15%;
        //   padding: 19px 13px;
        // }

        // /* AM/PM */
        // ::-webkit-datetime-edit-ampm-field {
        //   background-color: #7155d3;
        //   border-radius: 15%;
        //   color: #fff;
        //   padding: 19px 13px;
        // }

        // /* 'X' button for resetting/clearing time */
        // ::-webkit-clear-button {
        //   display: none;
        // }

        // /* Up/Down arrows for incrementing/decrementing the value */
        // ::-webkit-inner-spin-button {
        //   display: none;
        // }
      }
    }

    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    width: 100%;

    .map {
      display: flex;
      flex-basis: 495px;
      flex-grow: 1;

      em {
        transform: translateY(-85%);
        display: inline-flex;
        align-items: center;
        gap: 0.2em;
      }
    }

    aside {
      display: flex;
      flex-wrap: wrap;
      flex-grow: 4;
      align-items: flex-start;
      gap: 1rem;
      font-size: 1.2em;

      position: relative;
      flex-basis: 330px;

      span {
        white-space: nowrap;
      }

      > div {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 170px;
        flex-grow: 1;
        max-width: 280px;
        margin: auto;

        gap: 0.6rem;

        label,
        .label {
          flex-grow: 1;
          text-align: start;
        }

        button.red {
          width: auto;
          align-self: center;
        }

        button {
          width: 100%;
        }
      }

      > fieldset {
        display: flex;
        gap: 0.5rem;
        flex-grow: 1;
        flex-basis: 270px;
        align-items: flex-start;

        label,
        .label {
          flex-basis: 200px;
          flex-grow: 1;
        }

        label.duration,
        .label.duration {
          flex-basis: initial;
          flex-grow: initial;
          margin-left: auto;
        }

        input {
          min-width: 200px;
        }

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 0.3em;
          width: auto;
          margin-left: auto;
        }

        .row:last-of-type {
          .error {
            text-align: center;
            margin-top: 0.5rem;
            // margin-bottom: -0.8rem;
          }
        }

        button:last-child {
          margin-top: 1em;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.3em;
      }

      label,
      .label {
        position: relative;
        display: flex;
        flex-direction: column;
        color: gray;

        > span {
          font-size: 1.5em;
          color: white;
        }

        input {
          width: 100%;
        }

        > small {
          text-align: start;
        }

        .error {
          text-align: start;
        }
      }
    }
  }
}
