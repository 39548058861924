.root .gamePage {
  min-height: 100dvh;

  > header {
    padding-top: 2em;
  }

  .card {
    width: 100%;
  }

  .games > li {
    flex-basis: 300px;
    flex-grow: 1;

    > * {
      margin: 0.4em;
    }
  }

  .card {
    > main {
      > .row {
        display: flex;
        gap: 1em;

        justify-content: space-between;

        a {
          gap: 0.3em;
        }
      }
    }
  }
}
