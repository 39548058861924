.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker-popper {
  z-index: 2;
  // margin-left: 50% !important;
}

.react-datepicker {
  background-color: #110e14;
  box-shadow: 0px 0px 6px black;
  color: white;
  font-family: "Chivo Mono", monospace;
  font-size: 1.5rem;
  letter-spacing: 1px;
  // transform: translateX(-50%);
  border: none;
  padding: 1em;

  &.react-datepicker--time-only {
    height: 400px !important;

    * {
      width: auto !important;
      height: auto !important;
    }
  }

  .react-datepicker__month-dropdown {
    * {
      width: auto !important;
      height: auto !important;
    }
  }

  * {
    font-weight: 400 !important;
  }

  .react-datepicker__triangle {
    margin-left: 50% !important;
    transform: none !important;
    display: none;
  }

  .react-datepicker__input-container {
    display: flex;
    line-height: 1;
  }

  .react-datepicker__current-month {
    color: white;
    font-size: 1.2em;
  }

  .react-datepicker__header {
    background: none;
    border: none;
    padding-bottom: 0;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    top: 0.5em;
    font-size: 2em;
    background: none;
    width: 1em;
    padding: 0.6em;
  }

  .react-datepicker__navigation--previous {
    left: 0.5em;
  }

  .react-datepicker__navigation--next {
    right: 0.5em;
  }

  .react-datepicker__navigation-icon--previous {
    display: flex;
    align-items: center;
  }

  .react-datepicker__navigation-icon--next {
    display: flex;
    align-items: center;
  }

  .react-datepicker__navigation-icon::before {
    top: initial;
  }

  .react-datepicker__current-month--hasMonthDropdown,
  .react-datepicker__current-month--hasYearDropdown {
    display: none;
  }

  .react-datepicker__month-dropdown-container,
  .react-datepicker__year-dropdown-container {
    position: relative;
    font-size: 1.2em;
  }

  .react-datepicker__month-dropdown,
  .react-datepicker__year-dropdown {
    background-color: #110e14;
    color: white;
    font-family: inherit;
    letter-spacing: 1px;
    border: none;
    padding: 1em;
    width: auto;
    top: 1.4em;
    left: 50%;
    transform: translateX(-50%);
    // min-width: 7em;
    border: 1px solid;

    .react-datepicker__month-option,
    .react-datepicker__year-option {
      border-radius: 0;
      letter-spacing: 2px;
      padding: 0.05em;
      line-height: initial;
      white-space: nowrap;
      font-size: 0.8em;

      &:hover {
        background-color: rgb(18, 18, 18);
      }
    }

    .react-datepicker__month-option--selected_month,
    .react-datepicker__year-option--selected_year {
      background-color: #0075ff;

      span {
        display: none;
      }

      &:hover {
        background-color: #0075ff;
      }
    }
  }

  .react-datepicker__year-dropdown {
    height: 10em;
  }

  /* Dropdown arrows */
  .react-datepicker__navigation--years {
    background: none;
    line-height: 1.7em;
    text-align: center;
    cursor: pointer;
    padding: 0;
    border: 0.45em solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    text-indent: -999em;
    overflow: hidden;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__year-read-view--down-arrow {
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #ccc;
  }

  .react-datepicker__navigation--years.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #ccc;
  }

  .react-datepicker__month {
    margin-top: 0;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }

  .react-datepicker__day-names {
    margin-top: 0.4em;
  }

  .react-datepicker__day,
  .react-datepicker__day-name {
    color: white;
    font-weight: bold;
    width: 2.1em;
    line-height: 2.1em;
    font-size: 0.8em;
  }

  .react-datepicker__day-name {
    color: #5c5c5c;
  }

  .react-datepicker__day--today {
    font-weight: normal;
    background-color: rgb(10, 10, 10);
  }

  .react-datepicker__day--selected {
    color: white;
    background-color: #0075ff;
    border-radius: 50%;
  }

  .react-datepicker__day:hover {
    background-color: rgb(18, 18, 18);
  }

  .react-datepicker__day--selected:hover {
    background-color: #0075ff;
    border-radius: 50%;
  }

  .react-datepicker__day--keyboard-selected {
    background: #334150;
    border-radius: 50%;
    outline: none;
  }

  .react-datepicker__day--disabled,
  .react-datepicker__day--disabled:hover {
    color: #2a2a2a !important;
    background-color: transparent;
  }

  .react-datepicker__time-container {
    border: 0;
    height: initial;

    .react-datepicker__header--time {
      display: none;
    }

    .react-datepicker__time {
      background: #110e14;
      box-sizing: border-box;
      border-radius: 0;

      * {
        border-radius: 0;
      }
    }

    .react-datepicker__time-list {
      scroll-snap-type: y mandatory;
      height: calc(14em + (1.7rem / 2)) !important;
    }

    .react-datepicker__time-list-item {
      scroll-snap-align: start;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 0.4em 0.5em !important;
      font-size: 25px;
    }

    .react-datepicker__time-list-item:hover {
      background-color: rgb(18, 18, 18) !important;
    }

    .react-datepicker__time-list-item--disabled,
    .react-datepicker__time-list-item--disabled:hover {
      color: #2a2a2a !important;
      background-color: transparent !important;
    }

    .react-datepicker__time-list-item--selected,
    .react-datepicker__time-list-item--selected:hover {
      // font-size: 1.2em;
      background-color: #0075ff !important;
    }
  }
}
