.root .locationPage {
  a {
    display: initial;
  }

  address {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: 1em;
  }

  time {
    font-size: 4.5em;
    font-weight: 500;
    line-height: 1;
  }
}
