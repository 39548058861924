.root .posPage {
  gap: 25em;
  padding-bottom: 6em !important;

  > * {
    scroll-margin: -3.5em;
  }

  > section:nth-of-type(odd) {
    background: initial !important;
    padding: 0;
  }

  .languagePage {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 7em;
    gap: 0.3em;

    svg {
      font-size: 1.2em;
    }

    button {
      background: none;
      border: 12px solid;
      line-height: 1;
      padding: 0.15em 0.3em;
    }
  }

  .landingPage {
    justify-content: center;
    gap: 1em;
    scroll-margin: 0;

    .button {
      font-size: 4em;
      width: 10em;
      justify-content: center;
    }
  }

  .checkinPage {
    display: flex;
    gap: 1em;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;

    main {
      form {
        display: flex;
        flex-direction: column;
        width: 780px;
        gap: 1em;

        fieldset {
          flex-wrap: nowrap;

          label,
          .label {
            font-size: 3em;
            width: 100%;
          }

          input {
            width: 100%;
            max-width: calc(100vw - 1em);

            &.alt {
              border-color: #fff700;
            }
          }
        }

        .error {
          text-align: center;
          font-size: 1.8em;
          line-height: 1.2;
        }
      }
    }

    button {
      font-size: 2em;
      width: 100%;
      margin-top: 1em;
    }

    .sideLines {
      padding-top: 0.6em;
      padding-bottom: 0;
      max-width: 100%;
    }
  }

  h1,
  .h1 {
    text-align: center;
    font-size: 5em;
    margin: 0;
  }

  & ~ * .modal {
    font-size: 1.7em;
    max-width: 550px;

    ul {
      line-height: 1;
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;
        flex-direction: column;
      }
    }

    .button {
      margin: auto;
    }
  }

  & + footer {
    display: none !important;
  }
}
