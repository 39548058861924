.keyboard-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;

  transition: translate 0.2s 0s;

  .kanjis {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    background: #ececec;
    gap: 1px;
    padding: 1px;

    button {
      background: white;
      color: black;
      font-weight: inherit;
      border-radius: 0;
      padding: 0.2em 1.5em;
      border-top: 3px solid white;
      font-size: inherit !important;
      margin-top: 0 !important;
    }

    .selected {
      border-top: 0;
      border-bottom: 3px solid gray;
    }
  }
}

.simple-keyboard {
  color: black;
  border-radius: 0;

  .hg-button-default,
  .hg-button-abc,
  .hg-button-other,
  .hg-button-backspace {
    max-width: 80px;
  }

  .hg-button-contraction,
  .hg-button-contraction-disabled {
    font-size: 1.7em;

    span {
      height: 0.8em;
      width: 1.5em;
    }
  }

  .hg-button-contraction,
  .hg-button-contraction-disabled,
  .hg-button-size,
  .hg-button-size-disabled {
    max-width: 80px;
  }

  .hg-button-contraction-disabled,
  .hg-button-size-disabled {
    color: #d1d1d1;

    &:hover {
      cursor: initial;
    }

    &:active {
      background-color: white;
    }
  }

  [data-skbtn=""] {
    visibility: hidden;
  }

  &.hg-layout-tel {
    .hg-row {
      justify-content: center;
    }

    .hg-button {
      max-width: 200px;
    }
  }
}

.keyboard-wrapper.hidden:not(:focus),
.keyboard-wrapper.hidden:not(:focus-within) {
  translate: 0 100%;
}

.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: initial;
}

.keyboard-wrapper.en-US
  .hg-theme-default
  .hg-button.hg-standardBtn[data-skbtn="@"] {
  max-width: 80px;
}
